import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/wherePT.jpg"

function ParkingTower() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap parkingTWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap parkingTOverview">
          <div className="textWrap">
            <h2>주차타워</h2>
            <p className="bodyText">
              주차타워 시장은 전 세계적으로 그야말로 뜨거운 감자라고 할 수
              있습니다. 계속해서 늘어나는 차량과 한정된 주차공간으로 주차타워에
              대한 수요는 지속적으로 증가하고 있습니다. 하지만, 빈번한
              사고발생과 일차원 적인 입출고 흐름으로 인해 고객만족도는 높지않은
              편입니다. <br />
              <br />
              ELSA Platform의 실시간 감시 및 제어기능, 고장 즉시알림(모바일)
              등의 기능을 이용하여 다양한 고객만족 서비스를 만들어나갈 수
              있습니다. 타워 내 고장발생시 즉각 출동, 제어기능을 이용한 새로운
              서비스 출시 등 이전에는 꿈꾸지 못했던 새로운 주차타워 시장을
              개척해 나아가보세요.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ParkingTower
